import React, { useState } from "react"
import { graphql } from "gatsby"
import Nav from "../components/UI/Nav/Nav"
import Footer from "../components/UI/Footer/Footer"
import Pin from "../assets/icons/pin_2.svg"
import Mail from "../assets/icons/mail.svg"
import Iphone from "../assets/icons/iphone.svg"
import Modal from "../components/UI/Modal/Modal"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"

import "../assets/css/contact.css"

const Contact = ({ data }) => {
  const [modalShow, setModalShow] = useState(false)
  return (
    <Container className="no-gutters" fluid>
      <SEO />
      <Modal show={modalShow} handleClose={() => setModalShow(false)} />
      <Nav active="CONTACTO" />
      <Row className="my-3 my-md-5 justify-content-center align-items-center">
        <Col
          xs="12"
          md="6"
          className="order-md-1 order-3 d-flex justify-content-center"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3736.1117405951336!2d-100.41849068506859!3d20.54260848626376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d3450adcd6e331%3A0x9859cec2cdd3648d!2sPaseo%20de%20Estocolmo%20222%2C%20Tejeda%2C%2076190%20El%20Pueblito%2C%20Qro.!5e0!3m2!1ses-419!2smx!4v1601485442167!5m2!1ses-419!2smx"
            width="800"
            height="450"
            className="iframe"
            title="map"
            frameborder="0"
            style={{ border: "0" }}
            allowfullscreen=""
            aria-hidden="false"
          ></iframe>
        </Col>
        <Col xs="12" md="4" className="contact-data order-1 mb-md-0 mb-4">
          <p className="title">
            Llamanos o ven a visitarnos en nuestras oficinas de Querétaro:
          </p>
          <div className="d-flex align-items-center">
            <Pin />
            <div className="d-flex flex-column">
              <p className="my-0">Paseo de Estocolmo #222</p>
              <p className="my-0">Tejeda</p>
              <p className="my-0">76904 Corregidora, Qro.</p>
              <p className="my-0">México</p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Mail />
            <div className="d-flex flex-column">
              <p className="my-0">contactospr@gmail.com</p>
              <p className="my-0">atencionspr@gmail.com</p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Iphone />
            <div className="d-flex flex-column">
              <p className="my-0">Atención a clientes: 442 850 0328</p>
              <p className="my-0">Oficina: 442 196 4496</p>
            </div>
          </div>
          <Button
            className="btn-success btn-primary btn-lg w-100 mt-2"
            onClick={() => setModalShow(true)}
          >
            Solicita cotización
          </Button>
        </Col>
      </Row>
      <Footer />
    </Container>
  )
}

export const usQuery = graphql`
  query contactQueryAndImageQuery {
    header: file(relativePath: { eq: "index.png" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
export default Contact
